@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./css/_home.css";

@layer base {
  h1 {
    @apply text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold;
  }
  h2 {
    @apply text-lg sm:text-xl md:text-2xl lg:text-3xl font-bold;
  }
  h3 {
    @apply text-base sm:text-lg md:text-xl lg:text-2xl font-bold;
  }
  h4 {
    @apply text-lg lg:text-xl font-semibold;
  }
  h5 {
    @apply text-base font-semibold;
  }
  h6 {
    @apply text-sm font-semibold;
  }
  p {
    @apply text-base leading-7;
  }
}
